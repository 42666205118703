<template>
  <div>
    <h2>データクリーニング</h2>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body mt-1 mb-2">
        ここではデータ関連の作業を実施します。
      </div>
    </b-alert>
    <b-card
      title=""
    >
      <b-row>
        <b-col lg="8">
          <h3>メニューバーを作成する：学習Categoryなど</h3>
        </b-col>
        <b-col class="text-right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="makeData"
          >
            編集する
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    {{ jsonData }}
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BButton, BCard, BAlert,
} from 'bootstrap-vue'
import makeMune from '@/components/js/menubar/menu'
import Person from '@/components/js/test/Person'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BAlert,
  },
  directives: {
    Ripple,
  },
  props: {
  },
  data() {
    return {
      jsonData: '',
    }
  },
  mounted() {
    this.test1()
  },
  methods: {
    async makeData() {
      const response = await makeMune()
      if (response.status === 'success') {
        window.console.log('success', response.status)
        this.jsonData = response.data
      } else {
        window.console.log('error', response.msg)
      }
    },
    test1() {
      const person = new Person('ken')
      window.console.log('🔥', person.name, person.age, person)
      person.age = 100 // プライベート変数にアクセスできない。これはpersonオブジェクトにageを追加しているだけ。
      window.console.log('age 1', person.getAge(), person)
      person.setAge(100)
      window.console.log('age 2', person.getAge())
    },
  },
}
</script>

import store from '@/store'

export default function Person(name) {
  let age = 24
  this.name = name
  const uid = store.getters['userBasic/uid']
  return {
    getAge() {
      window.console.log('memo', uid)
      return age
    },
    setAge(val) {
      age += val
    },
  }
}

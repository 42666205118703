<template>
  <b-row class="match-height">
    <b-col lg="8">
      <EditDataCleaning />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import EditDataCleaning from '@/components/data/cleaning/EditDataCleaning.vue'

export default {
  components: {
    BRow,
    BCol,
    EditDataCleaning,
  },
}
</script>

import bigmenu from '@/components/conf/学習PF_大カテゴリリスト.json'
import middlemenu from '@/components/conf/学習PF_大category.json'
import smallmenu from '@/components/conf/学習PF_小カテゴリ.json'

export default function makeMune() {
  try {
    // window.console.log('memo', bigmenu)
    /* eslint-disable */
    let bigList = []
    for (const i in bigmenu) {
      const big = bigmenu[i]
      const middle = middlemenu.filter(v => v.categoryId === big.id)
      // window.console.log('memo', middle)
      let middleList = []
      for (const p in middle) {
        const mid = middle[p]
        const small = smallmenu.filter(v => v.groupId === mid.groupId)
        // window.console.log('small', small)
        let smallList = []
        for (const s in small) {
          const item = small[s]
          const data = {
            title: item.subjectLabel,
            route: null,
            resource: 'Auth',
            action: 'read',
          }
          smallList.push(data)
        }
        const d = {
          title: mid.groupLabel,
          children: smallList,
          resource: 'Auth',
          action: 'read',
        }
        middleList.push(d)
        // break
      }
      const dd = {
        title: big.category,
        children: middleList,
        resource: 'Auth',
        action: 'read',
      }
      bigList.push(dd)
      // break
    }
    // window.console.log('bigList', bigList)

    let jsonData = JSON.stringify(bigList)
    /* eslint-enable */
    const response = {
      status: 'success',
      data: jsonData,
    }
    return response
  } catch (error) {
    const response = {
      status: 'error',
      msg: error,
    }
    return response
  }
}
